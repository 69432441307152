import React, { Component } from 'react'
import SkillList from './SkillList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Parcours } from './IntroCv'
import { Diplomes } from './IntroCv'
import { Presentation } from './IntroCv'
import * as anim from '../../script/cvAnim'
import * as color from '../../script/Color'
import './cv.scss'
import ScrollAnimation from 'react-animate-on-scroll'

import Modal from './Modal'

class Cv extends Component {

  constructor(props) {
    super(props)

    this.interval = setInterval(() => {
      this.setState({
        age: this.getAge()
      })
    }, 100)

  }

  state = {
    modal: false,
    modalContent: [],
    skillComplete: '',
    age: 0
  }

  /**
   * Retourne mon age à 8 chiffres après la virgule
   */
  getAge() {
    let dob = 516216600000
    let age = ((new Date()).getTime() - dob) / (1000 * 60 * 60 * 24 * 365)
    return age.toFixed(8)
  }

  /**
   * Quand le composant est monté
   */
  componentDidMount = () => {
    anim.buildParcours();
    // color.colorInit();
    window.scrollTo(0, 0)
  }

  /**
   * Quand le composant est démonté
   */
  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  /**
   *  Retourne un titre de catégorie
   */
  categoryGeneration = (category) => {
    return (
      <span>
        <hr className=""></hr>
        <div className="categorySkill">{category}</div>
      </span>
    )
  }

  /**
   * Retourne l'ID d'un skill en fonction de son nom
   */
  getSkillId = (skillName) => {
    let skillId = ''
    const skillList = this.props.skills
    for (var i = 0; i < skillList.length; i++) {
      if (skillList[i].title === skillName) {
        skillId = skillList[i].id
      }
    }
    return skillId
  }

  /**
   * Retourne un Array de la liste des folios en fonction du skill
   */
  getFolios = (skillName, skillId) => {
    let folioList = []
    const { folios } = this.props

    Object.keys(folios).forEach(function (key) {
      for (var j = 0; j < folios[key].tools.length; j++) {
        if (skillId === folios[key].tools[j]) {
          folioList.push(folios[key]);
        }
      }
    });

    return folioList
  }

  /**
   * Affiche le modal au clic sur un skill
   */
  showModal = (e) => {
    let skillId = this.getSkillId(e.target.id)
    let skillComplete = this.getCompleteSkill(skillId)
    let folios = this.getFolios(e.target.alt, skillId)

    this.setState({
      modal: true,
      modalContent: folios,
      skillComplete: skillComplete
    })
  }

  /**
   * Retourne le tuple d'un skill en fonction de son ID
   */
  getCompleteSkill = (skillId) => {
    const { skills } = this.props
    let skillComplete = []

    Object.keys(skills).forEach(function (key) {
      if (skillId === skills[key].id) {
        skillComplete.push(skills[key]);
      }
    });
    return skillComplete[0]
  }

  /**
   * Ferme le modal
   */
  closeModal = () => {
    this.setState({
      modal: false
    })
  }

  render() {
    const { skills } = this.props;
    const { folios } = this.props

    return (
      <div className="page cv">
        <ScrollAnimation animateIn="fadeIn" duration={1}>
          <Presentation age={this.state.age} />
        </ScrollAnimation>

        {this.categoryGeneration('DIPLOMES')}

        <ScrollAnimation animateIn="fadeIn" duration={1}>
          <Diplomes />
        </ScrollAnimation>

        {this.categoryGeneration('PARCOURS')}

        <ScrollAnimation animateIn="fadeIn" duration={1}>
          <Parcours />
        </ScrollAnimation>

        {this.categoryGeneration('DEVELOPPEMENT')}

        <ScrollAnimation animateIn="fadeIn" duration={1}>
          <SkillList skills={skills} cat="dev" folios={folios} showModal={this.showModal} />
        </ScrollAnimation>

        {this.categoryGeneration('GRAPHISME')}

        <ScrollAnimation animateIn="fadeIn" duration={1}>
          <SkillList skills={skills} cat="graph" folios={folios} showModal={this.showModal} />
        </ScrollAnimation>

        {this.categoryGeneration('HOBBIES')}

        <ScrollAnimation animateIn="fadeIn" duration={1}>
          <SkillList skills={skills} cat="hobbies" folios={folios} />
        </ScrollAnimation>

        {this.categoryGeneration("EN COURS D'APPRENTISSAGE")}

        <ScrollAnimation animateIn="fadeIn" duration={1}>
          <SkillList skills={skills} cat="none" folios={folios} />
        </ScrollAnimation>

        {
          this.state.modal ? <div className="modalContainer" onClick={this.closeModal} ><div className="modal"><Modal folios={this.state.modalContent} skill={this.state.skillComplete} /></div></div> : null
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    folios: state.firestore.ordered.folios,
    auth: state.firebase.auth,
    skills: state.firestore.ordered.skills
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'folios' },
    { collection: 'skills', orderBy: 'title' }
  ])
)(Cv)