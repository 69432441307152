/**
|--------------------------------------------------
| Création d'un Skill en BDD
|--------------------------------------------------
*/
export const createSkill = (skill) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    var newSkill = firestore.collection("skills").doc();
    var data = {
      id: newSkill.id,
      ...skill,
      createdAt: new Date()
    };
    newSkill.set(data);
  }
};

/**
|--------------------------------------------------
| Mise à jur d'un skill en BDD
|--------------------------------------------------
*/
export const updateSkill = (skill) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection('skills').doc(skill.id).update({
      title: skill.title,
      category: skill.category,
      image: skill.image,
      rate: skill.rate,
      url: skill.url
    }).then(() => {
      dispatch({ type: 'UPDATE_SKILL_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'UPDATE_SKILL_ERROR' }, err);
    });
  }
};

/**
|--------------------------------------------------
| Suppression d'un skill en BDD
|--------------------------------------------------
*/
export const removeSkill = (skill, id) => {
  console.log(id)
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection('skills').doc(skill.id).delete(
    ).then(() => {
      dispatch({ type: 'REMOVE_SKILL_SUCCESS' });
    }).catch(err => {
      dispatch({ type: 'REMOVE_SKILL_ERROR' }, err);
    });
  }
};