import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* ========================================================================== */
/*                          PARTIE PRÉSENTATION DU CV                         */
/* ========================================================================== */

export const Presentation = (props) => {
  // Configuration de l'age
  let age = props.age
  let ageFormat = age.toString().split('.')

  return (
    <div id="introCv">
      <div className="photoProfileContainer">
      
        <div className="linkedInIconContainer" >
          <a className="linkedInIconLink" href="https://www.linkedin.com/in/simon-pelletier-a13822121/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="domiC linkedInIcon" icon={['fab', 'linkedin']} /></a>
        </div>

        <div className="gitIconContainer" >
          <a className="gitIconLink" href="https://github.com/simon-pelletier/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="domiC gitIcon" icon={['fab', 'git-square']} /></a>
        </div>

        <img src="/img/photo.jpg" className="photoProfile" alt="profilImage" />
      </div>
      
      <p className="nameDetail">Simon Pelletier, {ageFormat[0]} ans. <span className="ageDetail">.{ageFormat[1]}</span></p>
      <p className="domiC"><b>Développeur <span style={{color: 'white'}}>&</span> Graphiste</b></p>
      <p>Polymaniaque-Autodidacte / Heureux télétravailleur</p>
      <a href="/img/simon_pelletier_cv.pdf" target="_blank" className="domiC"><FontAwesomeIcon className="socialIcon domiC" icon={['fas', 'file-download']} /> <b>CV en PDF</b></a>

    </div>
  )
};

/* ========================================================================== */
/*                            PARTIE DIPLOME DU CV                            */
/* ========================================================================== */

export const Diplomes = () => {
  return (

    <div id="degrees">

      <div className="school col s12 offset-m2 m4" id="graphicSchool">
        <img className="degreeImg" src="https://firebasestorage.googleapis.com/v0/b/simoncv-5e45f.appspot.com/o/images%2Flayout%2Fesma.png?alt=media&token=ebcf4731-82f9-4a33-b78f-2a7b605fd785" alt="ESMA" />
        <span className="degreeDetail domiC">BTS Communication Visuelle - Multimédia (BAC+2)</span>
      </div>

      <div className="school col s12 m4" id="devSchool">
        <img className="degreeImg" src="https://firebasestorage.googleapis.com/v0/b/simoncv-5e45f.appspot.com/o/images%2Flayout%2Focr.png?alt=media&token=116a4fb9-0695-40fb-b8c7-435555ad8f02" alt="OpenClassRooms" />
        <span className="degreeDetail domiC">Développeur d'application (RNCP II) (BAC +3/4)</span>
      </div>

    </div>

  )
};

/* ========================================================================== */
/*                            PARTIE PARCOURS DU CV                           */
/* ========================================================================== */

export const Parcours = () => {
  return (
    <div className="parcoursBloc">
      <div id="parcours" className=""></div>
    </div>
  )
};