export const buildParcours = () => {
  //Fonction d'animation
  function animate(elem, style, unit, from, to, time, delay) {
    if (!elem) return;
    setTimeout(function() {
      var start = new Date().getTime(),
        timer = setInterval(function() {
          var step = Math.min(1, (new Date().getTime() - start) / time);
          elem.style[style] = from + step * (to - from) + unit;
          if (step === 1) clearInterval(timer);
        }, 25);
      elem.style[style] = from + unit;
    }, delay);
  }

  // Tableau de parcours
  var parcoursTab = [
    {
      date: 2006,
      title: "BAC STT Informatique",
    },
    {
      date: 2007,
      title: "MANAA",
    },
    {
      date: 2008,
      title: "BTS Communication Visuelle",
    },
    {
      date: 2009,
      title: "Kassius Paris - Graphiste",
    },
    {
      date: 2010,
      title: "Freelance - Graphiste",
    },
    {
      date: 0,
      title: "",
    },
    {
      date: 0,
      title: "",
    },
    {
      date: 0,
      title: "",
    },
    {
      date: 0,
      title: "",
    },
    {
      date: 0,
      title: "",
    },
    {
      date: 0,
      title: "",
    },
    {
      date: 0,
      title: "",
    },
    {
      date: 2018,
      title: "Développeur d'application RNCPII",
    },
    {
      date: 2019,
      title: "FHE - Développeur Web & Mobile",
    },
    {
      date: 2020,
      title: "O'CLOCK - Développeur & Tuteur (helper)",
    },
  ];

  // Récupération du bloc html #parcours
  var parcoursElt = document.getElementById("parcours");

  // Déclaration du bloc Parcours
  var parcoursContainer = document.createElement("div");
  parcoursContainer.id = "parcoursContainer";

  // Variables de configuration du bloc parcours
  var tab = parcoursTab;
  var start = 2006;
  var end = 2020;
  var range = end - start;
  var parts = 0;
  var temoin = 0;

  // Construction du bloc parcours
  for (var i = start; i <= end; i++) {
    if (tab[temoin]) {
      if (tab[temoin].date === i) {
        var point = document.createElement("div");
        point.className = "point domiB";

        var divider = 1.3;

        point.style.left = parts / divider + "vw";

        var dateViewContainer = document.createElement("div");

        // if (i === 2019) {
        //   dateViewContainer.className = 'dateViewContainer lastParcours';
        // } else {
        // }
        dateViewContainer.className = "dateViewContainer";

        var dateView = document.createElement("div");
        dateView.className = "dateView";
        dateView.innerHTML = tab[temoin].date;
        dateView.style.left = parts / divider + "vw";

        var titleViewContainer = document.createElement("div");
        titleViewContainer.className = "titleViewContainer";

        var titleView = document.createElement("div");
        titleView.className = "titleView";
        titleView.innerHTML = tab[temoin].title;

        dateViewContainer.appendChild(dateView);
        titleViewContainer.appendChild(titleView);
        dateViewContainer.appendChild(titleViewContainer);
        dateViewContainer.appendChild(point);

        parcoursContainer.appendChild(dateViewContainer);
      }
    }

    parts = parts + 100 / range;
    temoin++;
  }

  // Ajout du bloc parcours à la page
  parcoursElt.appendChild(parcoursContainer);

  // Création et animation de la ligne et des points
  var line = document.getElementById("parcoursContainer");

  let points = document.getElementsByClassName("dateViewContainer");
  let pointTimer = 0;

  if (points) {
    for (var j = 0; j < points.length; j++) {
      pointTimer = pointTimer + 300;
      animate(points[j], "display", "", "block", 0, 0, pointTimer);
      animate(points[j], "opacity", "", 0, 1, 500, pointTimer);
    }
  }

  animate(line, "width", "%", 0, 80, 500, 0);
};
