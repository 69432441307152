import React from 'react'
import Zoom from 'react-reveal/Zoom'

const ModalSummary = (props) => {
  const folio = props.folio

  return (
    <Zoom>
    <div className="folioItem">
      <div className="folioTitle"><p>{folio.title}</p></div>
      <div className="folioItemOverlay"></div>
      <img className="folioMiniature" src={folio.miniature} alt={folio.title} />
    </div>
    </Zoom>
  )
}

export default ModalSummary
