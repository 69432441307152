import React from 'react'
import ModalSummary from './ModalSummary'
import { Link } from 'react-router-dom'

const Modal = ({ folios, skill }) => {
    // Affichage du modal d'un folio, au clic sur une compétence
    return (
        <div>
            <div className="modalInfos">
                <img className="modalImg" src={skill.url} alt="" />
                <div className="modalSkillTitle">{skill.title}</div>

            </div>

            <div className="folio-list" id="grid" >
                {folios && folios
                    .map(folio => {
                        return (
                            // Pour chaque projet lié à cette compétence affichage d'un élément de grille avec le folio cliquable
                            <Link className={folio.type + " gridItem"} to={'/folio/' + folio.id} key={folio.id}>
                                <ModalSummary folio={folio} />
                            </Link>

                        )
                    })}
            </div>
        </div>
    )
}

export default Modal