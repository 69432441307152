import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createFolio } from '../../store/actions/folioActions'
import { Redirect } from 'react-router-dom'
import { storage } from '../../config/fbConfig'

class CreateFolio extends Component {
  state = {
    title: '',
    description: '',
    type: '',
    link: '',
    folder: [],
    miniature: null,
    tools: []
  }

  /**
   * Envoi en State les changement de la fonction onChange 
   */
  handleChange = (e) => {
    if (e.target.files && e.target.id === 'miniature') {
      this.setState({
        miniature: e.target.files[0]
      })
    } else if (e.target.files && e.target.id === 'folder') {
      const folderTemp = [];
      for (var i = 0; i < e.target.files.length; i++) {
        folderTemp.push(e.target.files[i])
      }
      this.setState({
        folder: folderTemp
      })
    } else {
      this.setState({
        [e.target.id]: e.target.value
      })
    }
  }

  /**
   * Retourne un nom d'image
   */
  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  /**
   * Au clic sur upload
   */
  handleUpload = (e) => {
    e.preventDefault();
    // Déclaration de la miniature
    const { miniature } = this.state;
    // Création du nom de la miniature
    const randomName = this.guid();
    //Envoi de la miniature sur Firestore
    const uploadTask = storage.ref(`images/folios/mini/${randomName}`).put(miniature);
    uploadTask.on('state_changed',
      (snapshot) => {
        // progress
      },
      (error) => {
        // error
        console.log(error);
      },
      () => {
        //complete
        // Retourne en state l'url de la miniature du storage
        storage.ref('images/folios/mini').child(randomName).getDownloadURL().then(url => {
          this.setState({
            miniature: url
          })
        })
      });
  }

  /**
   * A l'envoi du folio
   */
  handleUploads = (e) => {
    e.preventDefault();
    this.setState({
      folder: []
    })
    const { folder } = this.state;
    for (var i = 0; i < folder.length; i++) {
      this.uploadFolios(folder[i])
    }
  }


  /**
   * Envoi l'image contenu dans le folder de folio à Firestore
   */
  uploadFolios(folder) {
    // Création du nom de l'image
    const randomName = this.guid();
    const uploadTask = storage.ref(`images/folios/${randomName}`).put(folder);
    uploadTask.on('state_changed',
      (snapshot) => {
        // progress
      },
      (error) => {
        // error
        console.log(error);
      },
      () => {
        //complete
        // Retourne en state l'url de l'image du storage
        storage.ref('images/folios').child(randomName).getDownloadURL().then(url => {
          this.setState({
            folder: [...this.state.folder, url]
          })
        })
      });
  }

  /**
   * onSubmit Form
   */
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    this.props.createFolio(this.state);
    this.props.history.push('/');
  }

  /**
   * Affichage du bouton d'envoi
   */
  renderButton() {
    // Si le titre, la description, le type, le lien et la minature sont renseignés
    if (this.state.title !== '' && this.state.description !== '' && this.state.type !== '' && this.state.link !== '' && this.state.miniature !== '') {
      // Retourne le bouton actif
      return (
        <div className="input-field">
          <button className="waves-effect waves-light btn-large" >Create</button>
        </div>
      )
    } else {
      // Retourne le bouton inactif
      return (
        <div className="input-field">
          <button className="waves-effect waves-light btn-large" disabled>Create</button>
        </div>
      )
    }
  }

  render() {
    const { auth } = this.props;
    // Si le visiteur n'est pas connecté il est redirigé vers la page d'accueil
    if (!auth.uid) return <Redirect to='/' />
    return (
      <div className="container">
        <form className="row" onSubmit={this.handleSubmit}>
          <h5 className="grey-text text-lighten-2 col s12 center-align">Create a New Folio</h5>
          <div className="row">
            <div className="input-field col s6 offset-s3">
              <input type="text" id='title' onChange={this.handleChange} />
              <label htmlFor="title">Title</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s6 offset-s3">
              <input type="text" id='description' onChange={this.handleChange} />
              <label htmlFor="description">Description</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s6 offset-s3">
              <input type="text" id='link' onChange={this.handleChange} />
              <label htmlFor="link">Link</label>
            </div>
          </div>

          <div className="row">
            <div className="row center-align">
              <label className="col s2 offset-s3">
                <input id="type" className="with-gap" name="type" type="radio" value="app" onChange={this.handleChange} />
                <span>App</span>
              </label>
              <label className="col s2">
                <input id="type" className="with-gap" name="type" type="radio" value="print" onChange={this.handleChange} />
                <span>Print</span>
              </label>
              <label className="col s2">
                <input id="type" className="with-gap" name="type" type="radio" value="video" onChange={this.handleChange} />
                <span>Vidéo</span>
              </label>
            </div>
          </div>

          <div className="row">
            <div className="input-field center-align col s8 offset-s2">
              MINIATURE : 300 * 150 PNG-File<br />
              <input type="file" id="miniature" className="row btn waves-effect waves-light" accept="image/png" onChange={this.handleChange} /><br />
              <img src={this.state.miniature} alt=""></img><br />
              <button className="row btn waves-effect waves-light" onClick={this.handleUpload} ><i className="material-icons right">send</i>UPLOAD IMAGE</button>
            </div>
          </div>

          <div className="row">
            <div className="input-field center-align col s8 offset-s2">
              FOLDER : 1600 * 800 -File<br />
              <input id="folder" type="file" name="folder" multiple onChange={this.handleChange} /><br /><br />
              <button className="row btn waves-effect waves-light" onClick={this.handleUploads} ><i className="material-icons right">send</i>UPLOAD IMAGES</button>
            </div>
          </div>

          <div className="row center-align">
            {this.renderButton()}
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createFolio: (folio) => dispatch(createFolio(folio))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFolio)