import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navbar from './components/layout/Navbar'
import SignIn from './components/auth/SignIn'
import Portfolio from './components/portfolio/Portfolio'
import FolioDetails from './components/portfolio/FolioDetails'
import Cv from './components/cv/Cv'
import Contact from './components/contact/Contact'
import CreateFolio from './components/admin/CreateFolio'
import CreateSkill from './components/admin/CreateSkill'
import Footer from './components/layout/Footer'
import MentionsLegales from './components/legal/Ml'
import Cgv from './components/legal/Cgv'
import { SocialIcons } from './components/layout/SocialIcons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fab)
library.add(fas)

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path='/'component={Portfolio} />
            <Route path='/folio/:id' component={FolioDetails} />
            <Route path='/portfolio'component={Portfolio} />
            <Route path='/cv'component={Cv} />
            <Route path='/contact'component={Contact} />
            <Route path='/signin' component={SignIn} />
            <Route path='/createFolio' component={CreateFolio} />
            <Route path='/createSkill' component={CreateSkill} />
            <Route path='/mentionsLegales' component={MentionsLegales} />
            <Route path='/CGV' component={Cgv} />
            </Switch>
          <SocialIcons />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
