import React from 'react'
import FolioSummary from './FolioSummary'
import { Link } from 'react-router-dom'

/**
 * Retourne l'affichage d'une liste de folio selon la catégorie en props
 */
const FolioList = ({ folios, category }) => {
  return (
    <div className="folio-list" id="grid" >
      {folios && folios.filter(folio => {
        // Filtre les folios en fonction de la catégorie
        if (category === 'app') {
          return folio.type === "app"
        } else if (category === 'print') {
          return folio.type === "print"
        } else if (category === 'video') {
          return folio.type === "video"
        } else {
          return folio
        }
      })
        // Range les folios aléatoirement
        .sort(function (a, b) { return 0.5 - Math.random() })
        // Pour chaque folio filtré : affiche le folioSummary et un lien
        .map(folio => {
          return (

            <Link className={folio.type + " gridItem"} to={'/folio/' + folio.id} key={folio.id}>
              <FolioSummary folio={folio} />
            </Link>

          )
        })}
    </div>
  )
}

export default FolioList