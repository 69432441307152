import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SocialIcons = () => {
  return (
    <div id="socialIcons" className="social-icons icon-circle list-unstyled list-inline" >
        <a className="socialIconLink domiC" href="https://www.linkedin.com/in/simon-pelletier-a13822121/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="socialIcon" icon={['fab', 'linkedin']} /></a>
        <a className="socialIconLink domiC" href="https://github.com/simon-pelletier/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="socialIcon" icon={['fab', 'git-square']} /></a>
    </div>
  )
};