import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSkill } from '../../store/actions/skillActions'
import { Redirect } from 'react-router-dom'
import { storage } from '../../config/fbConfig'

class CreateSkill extends Component {
  state = {
    title: '',
    category: '',
    rate: '50',
    url: '',
    image: null
  }

  /**
   * Envoi en State les changement de la fonction onChange 
   */
  handleChange = (e) => {
    if (e.target.files && e.target.id === 'image') {
      this.setState({
        [e.target.id]: e.target.files[0]
      })
    } else {
      this.setState({
        [e.target.id]: e.target.value
      })
    }
  }

  /**
   * Envoi en State les changement de la fonction onChange 
   */
  handleUpload = (e) => {
    e.preventDefault();
    const { image } = this.state;
    const uploadTask = storage.ref(`images/skills/${image.name}`).put(image);
    uploadTask.on('state_changed',
      (snapshot) => {
        // progress
      },
      (error) => {
        // error
        console.log(error);
      },
      () => {
        //complete
        storage.ref('images/skills').child(image.name).getDownloadURL().then(url => {
          this.setState({
            url: url,
            image: image.name
          })
        })
      });
  }

  /**
   * onSubmit Form
   */
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createSkill(this.state);
    this.props.history.push('/cv');
  }

  /**
   * Retourne le bouton d'envoi
   */
  renderButton() {
    // Si le titre, la catégorie, la note, et l'url sont bien renseignés
    if (this.state.title !== '' && this.state.category !== '' && this.state.rate !== '' && this.state.url !== '') {
      // Retourne le bouton actif
      return (
        <div className="input-field">
          <button className="waves-effect waves-light btn-large" >Create</button>
        </div>
      )
    } else {
      // Retourne le bouton inactif
      return (
        <div className="input-field">
          <button className="waves-effect waves-light btn-large" disabled>Create</button>
        </div>
      )
    }
  }

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to='/' />
    return (
      <div className="container">
        <form className="row" onSubmit={this.handleSubmit}>
          <h5 className="grey-text text-lighten-2 col s12 center-align">Create a New Skill</h5>
          <div className="row">
            <div className="input-field col s6 offset-s3">
              <input type="text" id='title' onChange={this.handleChange} />
              <label htmlFor="title">Title</label>
            </div>
          </div>

          <div className="row">
            <div className="row center-align">
              <label className="col s2 offset-s3">
                <input id="category" className="with-gap" name="category" type="radio" value="dev" onChange={this.handleChange} />
                <span>Dev</span>
              </label>
              <label className="col s2">
                <input id="category" className="with-gap" name="category" type="radio" value="graph" onChange={this.handleChange} />
                <span>Graphisme</span>
              </label>
              <label className="col s2">
                <input id="category" className="with-gap" name="category" type="radio" value="hobbies" onChange={this.handleChange} />
                <span>Hobbies</span>
              </label>
            </div>
          </div>

          <div className="row">
            <p className="range-field col s8 offset-s2">
              <input type="range" id="rate" min="0" max="100" step="10" onChange={this.handleChange} />
              <label htmlFor="rate">Rate : {this.state.rate}</label>
            </p>
          </div>

          <div className="row">
            <div className="input-field center-align col s8 offset-s2">
              200 * 200 PNG-File<br />
              <input type="file" id="image" className="row btn waves-effect waves-light" accept="image/png" onChange={this.handleChange} /><br />
              <img src={this.state.url} alt=""></img><br />
              <button className="row btn waves-effect waves-light" onClick={this.handleUpload} ><i className="material-icons right">send</i>UPLOAD IMAGE</button>
            </div>
          </div>

          <div className="row center-align">
            {this.renderButton()}
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createSkill: (skill) => dispatch(createSkill(skill))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSkill)