import React, { Component } from 'react'
import './contact.scss'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Bounce from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Bounce'
import * as color from '../../script/Color'

class Contact extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            name: '',
            email: '',
            message: '',
            temoin: ''
        }
    }

    /**
     * Quand le composant est monté
     */
    componentDidMount = () => {
        // color.colorInit();
        window.scrollTo(0, 0)
    }

    /**
     * Envoi en State les changement de la fonction onChange 
     */
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    /**
     * Vérifie si l'e-mail est valide
     */
    isEmailValid(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
     * onSubmit Form
     */
    handleSubmit = (event) => {
        var self = this;
        event.preventDefault();

        //Envoi des informations du formulaire au fichier php - POST
        fetch('https://www.simonpelletier.net/email.php', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "name": self.state.name,
                "email": self.state.email,
                "message": self.state.message
            })

        })
            // Retourne en state le message 'merci pour votre message'
            .then((response) => {
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                    temoin: 'Merci pour votre message.'
                })
            })
            // En cas d'erreur
            .catch((error) => {
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                    temoin: 'Votre message n\'a pas été envoyé, merci de réessayer plus tard.'
                })
            })
    };

    /**
     * Retourne le bouton d'envoi de formulaire
     */
    renderButton() {
        // Si les inputs name, email et message sont renseignés et que l'email est valide
        if (this.state.name !== '' && this.state.email !== '' && this.state.message !== '' && this.isEmailValid(this.state.email)) {
            // Retourne le bouton activé
            return (
                <div className="input-field">
                    <button onClick={this.handleSubmit} className="waves-effect waves-light btn-large" >Envoyer</button>
                </div>
            )
        } else {
            // Retourne le bouton désactivé
            return (
                <div className="input-field">
                    <button className="waves-effect waves-light btn-large" disabled>Envoyer</button>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="page container contact">

                <Fade>
                    <div className="contactDetails">
                        <p>Conception et réalisation de sites web et applications.</p>
                        <p>Charte graphique - Logo - Animation - Vidéo</p>
                    </div><br />
                    <div id="infosContact">
                        <p>Vous avez des questions, ou désirez un devis,</p>
                        <p>vous pouvez me contacter <b>via ce formulaire.</b></p>
                        <p>Par téléphone au <span className="crochets domiC">06 28 80 93 86</span><br />Ou par email : <a href="mailto:contact@simonpelletier.net"><span className="crochets domiC">contact@simonpelletier.net</span></a></p>
                    </div>

                </Fade>

                <form className="formContact" >
                    <Bounce>
                        <div className="row">
                            <div className="input-field col s12 m6 offset-m3">
                                <i className="material-icons prefix">account_circle</i>
                                <input type="text" id='name' className="inputContact" onChange={this.handleChange} value={this.state.name} />
                                <label htmlFor="name" className='helperContact'>Votre nom</label>
                            </div>
                        </div>
                    </Bounce>
                    <Bounce>
                        <div className="row">
                            <div className="input-field col s12 m6 offset-m3">
                                <i className="material-icons prefix">email</i>
                                <input type="email" id='email' className='inputContact' onChange={this.handleChange} value={this.state.email} />
                                <label htmlFor="email" className='helperContact'>Votre email</label>
                                <span className="helper-text" data-error="Email non validé" data-success="Email validé"></span>
                            </div>
                        </div>
                    </Bounce>
                    <Bounce>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">insert_comment</i>
                                <textarea id="message" className="materialize-textarea inputContact" onChange={this.handleChange} value={this.state.message}></textarea>
                                <label htmlFor="message" className='helperContact'>Votre message</label>
                            </div>
                        </div>
                    </Bounce>
                    <div className="row center-align">{this.state.temoin}</div>
                    <Zoom>
                        <div className="row center-align">
                            {this.renderButton()}
                        </div>
                    </Zoom>
                </form>

            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        folios: state.firestore.ordered.folios,
        skills: state.firestore.ordered.skills
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'folios' },
        { collection: 'skills' }
    ])
)(Contact)