import React from 'react'
import { connect } from 'react-redux'
import Zoom from 'react-reveal/Zoom'

/**
 * Retourne l'affichage d'un folio
 */
const FolioSummary = (props) => {
  const folio = props.folio

  return (
    <Zoom>
      <div className="folioItem">
        <div className="folioTitle"><p>{folio.title}</p></div>
        <div className="folioItemOverlay"></div>
        <img className="folioMiniature" src={folio.miniature} alt={folio.title} />
      </div>
    </Zoom>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps)(FolioSummary)
