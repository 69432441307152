import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateSkill } from '../../store/actions/skillActions'
import { removeSkill } from '../../store/actions/skillActions'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Fade from 'react-reveal/Fade'

class SkillSummary extends Component {

  constructor(props) {
    super(props);

    const skill = props.skill;
    if (skill) {
      this.state = {
        id: skill.id,
        title: skill.title,
        category: skill.category,
        image: skill.image,
        rate: skill.rate,
        url: skill.url
      }
    }
  }

  state = {
    id: '',
    title: '',
    category: '',
    image: '',
    rate: '',
    url: '',
    modal: false,
    folios: []
  }

  /**
   * Envoi en State les changement de la fonction onChange 
   */
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, () => {
      this.props.updateSkill(this.state);
    })
  }


  /**
   * Action remove skill
   */
  handleRemove = (e) => {
    e.preventDefault();
    this.props.removeSkill(this.state);
  }


  /**
   * Affichage des skills si authentification
   */
  adminView() {
    const skill = this.state;
    return (
      <span>
        <img className="skillLogo" src={skill.url} alt={skill.image} height="50" width="50" />
        <div className="row">
          <p className="range-field col s8 offset-s2">
            <input type="range" id="rate" min="0" max="100" step="10" value={skill.rate} onChange={this.handleChange} />
            <label htmlFor="rate">Rate : {skill.rate}</label>
          </p>
        </div>
        <span className="descriptionSkills">{skill.title}</span>
        <div className="btn deleteSkillBtn center-align col s12" onClick={this.handleRemove}>DELETE</div>
      </span>
    )
  }

  /**
   * Affichage des skills si visiteur
   */
  visitorView() {
    const skill = this.state;
    return (
      <Fade>
        <div className="contentSkill" >
          <div className="detailCodeSkillContainer">
            <div className="detailCodeSkill hoverDomiC">{skill.title}</div>
          </div>

          <div className="skillImgOverlay" id={skill.title} onClick={this.props.showModal}>
            <img className="skillImg" src={skill.url} alt={skill.title} />
          </div>
        </div>
      </Fade>
    )
  }

  render() {
    const skill = this.state;
    const auth = this.props.auth;
    if (skill) {
      return (
        <div className="skillItem skillCode center col s4 m3 l2">
          {
            auth.uid
              ? this.adminView()
              : this.visitorView()
          }
        </div>

      )
    }
  }
}

const mapStateToProps = (state, key) => {
  const id = key.skill.id;
  const skills = state.firestore.data.skills;
  const skill = skills ? skills[id] : null
  return {
    skill: skill,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSkill: (skill) => dispatch(updateSkill(skill)),
    removeSkill: (skill) => dispatch(removeSkill(skill))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'skills' }
  ])
)(SkillSummary)