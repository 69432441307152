import React, { Component } from 'react'
import SkillSummary from './SkillSummary'

class SkillList extends Component {

  render () {

  const {cat} = this.props
  const {skills} = this.props
  const {folios} = this.props

  let apprentissage = 'skillArea'

  // Attribution d'une classe 'apprentissage' aux compétences ayant un rating de 0
  if (cat === 'none'){
    apprentissage = 'apprentissage'
  }

  return (

    <div className={apprentissage + " skillBlock center-align row"}  >

      { skills && skills
      .filter(skill => {
        if (cat === 'none'){
          return skill.rate === "0" || skill.rate === 0
        } else {
          return skill.category === cat && skill.rate > 0
        }
      })
      // range de façon aléatoire
      //.sort(function(a, b){return 0.5 - Math.random()})
      // range par note
      .sort(function(a, b){
        if (Number(a.rate) < Number(b.rate)) {
          return 1
        } else {
          return -1
        }
      })
      .map(skill => {
        return (
            // Pour chaque skill obtenu : affiche le composant SkillSummary avec ces props
            <SkillSummary skill={skill} key={skill.id} folios={folios} showModal={this.props.showModal}/>
        )
      })}

    </div>
    
  )
  }
  
}

export default SkillList
