import React, { Component } from 'react'
import FolioList from './FolioList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import './portfolio.scss'
import * as color from '../../script/Color'

class Portfolio extends Component {
  
  state = {
    category: ''
  }

  /**
   * OnClick sur une nouvelle catégorie
   */
  categorySwitch = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.name
    })
  }

  /**
   * Quand le composant est monté
   */
  componentDidMount = () => {
    // Lance le script de couleur dominante
    // color.colorInit();
    // Scroll To Top
    window.scrollTo(0, 0)
  }

  render() {
    const { folios } = this.props;
    return (
      <div className="portfolio page">

        <div className="folioFilter center domiB">
          <a className="folioFilterLink small material-icons" href="/" id="category" name="all" onClick={this.categorySwitch} title="ALL">apps</a>
          <a className="folioFilterLink small material-icons" href="/" id="category" name="app" onClick={this.categorySwitch} title="WEB">computer</a>
          <a className="folioFilterLink small material-icons" href="/" id="category" name="print" onClick={this.categorySwitch} title="PRINT">photo</a>
          <a className="folioFilterLink small material-icons" href="/" id="category" name="video" onClick={this.categorySwitch} title="VIDEO">videocam</a>
        </div>

        <FolioList folios={folios} category={this.state.category} />

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    folios: state.firestore.ordered.folios,
    auth: state.firebase.auth,
    skills: state.firestore.ordered.skills
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'folios' },
    { collection: 'skills' }
  ])
)(Portfolio)