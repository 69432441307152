import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer extends Component {
    
    render(){
        // Définit la couleur du '&'
        var andStyle = {
            color: 'white',
          };

        return(
            <div className="footer">
                ©2019 Simon Pelletier - Perpignan [ 66 ]<br/>
                <span className="footerDescription domiC">Développeur <span style={andStyle}>&</span> Graphiste</span><br/>
                <Link className="linkFooter" to={'/mentionsLegales'} >Mentions Légales</Link>
            </div>
            
        );
    }
}

export default Footer;