import React, { Component } from 'react';

class Cgv extends Component {
    render () {
        return (
            <div className="page container contact">
                <div id="cgvText" className="col s10 offset-s1">
                    <p><b>1 &#8211; Parties du contrat</b></p>
                    <p>Le terme «Client» désigne toute personne morale ou physique bénéficiaire des services de Simon Pelletier.</p>
                    <p>Le terme «Prestataire» désigne Simon Pelletier, graphiste indépendant.</p>
                    <p><b>2 &#8211; Généralités</b></p>
                    <p>Les présentes CGV (Conditions Générales de Vente) ont pour objet de définir les droits et obligations des parties lors de la vente de produits réalisés par le Prestataire pour ses Clients dans le cadre de son activité commerciale de création graphique. Elles remplacent tout accord, arrangement ou contrat antérieur, écrit ou non écrit, conclu entre les parties et se rapportant au même objet. Toute convention dérogatoire ou complémentaire au présent contrat devra être constatée par écrit. Les intitulés des articles du présent contrat ne figurent que pour plus de commodité et n’affectent en aucune manière le sens des dispositions auxquelles ils font référence. Aucun fait de tolérance par Simon Pelletier ne saurait constituer une renonciation de sa part à une quelconque disposition du présent contrat. Le Prestataire se réserve le droit de modifier ses CGV, ses formules et ses tarifs à tout moment et sans préavis. Ces modifications n’auront aucune incidence sur les commandes en cours. Si le Client est un particulier, il reconnaît être majeur conformément aux lois du pays où il réside. Le Client faisant appel aux services de Simon Pelletier reconnaît avoir pris connaissance et accepté sans réserve les CGV suivantes, ainsi que les mises en garde énoncées dans l’annexe concernant les lois de la propriété intellectuelle. Pour se faire le Client apposera lors de la commande sa signature précédée de la mention manuscrite «lu et approuvé» au bas des CGV imprimées. Toutes les pages de ce document devront être paraphées.</p>
                    <p><b>3 &#8211; Responsabilité du client</b></p>
                    <p>Le Client s’engage à fournir des informations justes et sincères et s’engage à prévenir Simon Pelletier de tout changement concernant les données fournies. Le Client sera seul responsable des éventuels dysfonctionnements qui pourraient résulter d’informations erronées. Le Client doit maintenir une adresse e-mail et une adresse postale valides.</p>
                    <p><b>4 &#8211; Engagements des parties</b></p>
                    <p>D’une façon générale, le Client et le Prestataire s’engagent à collaborer activement afin d’assurer la bonne exécution du contrat. Chacun s’engage à communiquer toutes les difficultés dont il aurait connaissance, au fur et à mesure, pour permettre à l’autre partie de prendre les mesures nécessaires.</p>
                    <p><b>a/ Le Client</b></p>
                    <p>• Établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des parties, après avoir été approuvé par le Prestataire. Dans le cas où des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces dernières seront facturées en sus du devis initial.</p>
                    <p>• Remettre au Prestataire le bon de commande/devis (daté et signé).</p>
                    <p>• Fournir tous les éléments documentaires graphiques et textuels nécessaires à la bonne réalisation du contrat (notamment dans les bons formats exploitables en fonction des supports visés).</p>
                    <p>• Disposer des droits nécessaires sur les éléments fournis ci-dessus. Seule la responsabilité du commanditaire peut être engagée à ce titre.</p>
                    <p>• Collaborer activement à la réussite du projet en apportant au Prestataire dans les délais utiles toutes les informations et documents nécessaires à la bonne appréhension des besoins et à la bonne exécution des prestations.</p>
                    <p>• Se conformer strictement aux préconisations techniques et créatives faites par le Prestataire.</p>
                    <p>• Garantir le Prestataire contre toute action qui pourrait lui être intentée du fait du caractère des données ou informations (textes, images, sons) qui auraient été fournies ou choisies par le Client.</p>
                    <p>• Régler dans les délais précis les sommes dues au Prestataire.</p>
                    <p>• Informer le Prestataire d’une éventuelle mise en concurrence avec d’autres Prestataires.</p>
                    <p><b>b/ Le Prestataire</b></p>
                    <p>• Au besoin le Prestataire pourra intervenir dans l’élaboration du cahier des charges, conjointement avec le Client.</p>
                    <p>• Le Prestataire garantit que les créations sont juridiquement disponibles et ne sont pas grevées de droit des tiers, salariés ou non du Prestataire, pour les utilisations prévues au titre du contrat.</p>
                    <p>• Le Prestataire s’engage à informer de manière régulière et efficace le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client.</p>
                    <p>• Au titre de la confidentialité et pendant toute la durée des présentes et même après leur cessation pour quelque cause que ce soit, le Prestataire s’engage à conserver strictement confidentiel l’ensemble des informations et documents de quelque nature que ce soit relatifs au Client, auxquels il aurait pu avoir accès dans le cadre notamment de l’exécution de la présente mission.</p>
                    <p><b>5 &#8211; Bon de commande et début des travaux</b></p>
                    <p>Le devis et les CGV signés par le Client valent pour accord et bon de commande et doivent, si spécifié dans le devis, être accompagnés d’un paiement de 30% du montant total H.T indiqué sur le devis. Les travaux débuteront lorsque le devis et les CGV seront signés, lorsque l’acompte de 30%, si spécifié, sera encaissé, et lorsque les éléments documentaires graphiques et textuels nécessaires à la bonne réalisation du contrat seront à la disposition du Prestataire.</p>
                    <p><b>6 &#8211; Factures et règlements</b></p>
                    <p>Les paiements se font par virement bancaire ou par chèque à l’ordre de « Simon Pelletier ». Sauf délai de paiement supplémentaire convenu par accord entre les deux parties et figurant sur la facture, le paiement s’effectue au plus tard au trentième jour suivant la date de facturation (cf : C. Com. art L. 441-6, al. 2 modifié de la loi du 15 mai 2001). Tout retard de paiement pourra donner lieu à des pénalités de retard exigibles sans rappel, au taux de 10% de la facture totale par mois de retard (lutte contre les retards de paiement/article 53 de la loi NRE), ainsi qu’à une indemnité forfaitaire de 40€ (C. Com. art. D441-5). Les prix stipulés sur le devis sont valables un mois à partir de la date d’émission de celui-ci. Ceux-ci restent fermes et non révisables à la commande si celle-ci intervient durant ce délai. Les prestations à fournir sont celles clairement énoncées sur le devis, de manière corrolaire toute prestation non-énoncée ne sera pas comprise et fera l’objet d’un devis gratuit complémentaire. Les tarifs sont exprimés en Euros et hors taxes. Ils ne sont pas soumis à la TVA (TVA non applicable, art. 293B du CGI). La facture émise au Client reprend le montant brut énuméré dans le devis. Pas d’escompte en cas de paiement anticipé. Les frais de poursuites et d’honoraires pour le recouvrement de factures impayées sont à la charge du Client.</p>
                    <p><b>7 &#8211; Frais annexes</b></p>
                    <p>Les éléments divers éventuellement nécessaires à la réalisation des prestations de Simon Pelletier et ne relevant pas de ses offres telles que des polices de caractères, des photographies ou illustrations issues de banques d’images, des vidéos, des enregistrements sonores, ne sont pas compris dans les prix indiqués. Concernant ces éléments, le Client s’engage à obtenir toutes les autorisations nécessaires et à prendre à sa charge tout paiement afférent, notamment en vertu des droits d’auteur et des droits de la personnalité. Le règlement des frais d’impression et/ou d’hébergement internet sera effectué par le Client; directement auprès du fournisseur et Prestataire extérieur. Les déplacements nécessaires à la bonne réalisation du contrat pourront également être facturés au Client.</p>
                    <p><b>8 &#8211; Livraison</b></p>
                    <p>Le délai de livraison est toujours indiqué sur le devis. Il correspond à une période nécessaire à la réalisation des produits et prend effet à la date d’encaissement de la commande selon les conditions définies précédemment. Le délai de livraison peut être différé si le Prestataire n’est pas en possession de la totalité des documents (photos, textes, vidéos…) fournis par le Client pour la réalisation de son projet ou si le Client ne procède pas aux validations nécessaire à sa poursuite.</p>
                    <p><b>9 &#8211; Acompte et annulation de commande</b></p>
                    <p>En cas de rupture du contrat avant son terme par le Client ou le Prestataire, le Client s’engage formellement à régulariser et rétribuer les montants relatifs au calendrier en cours, aux postes réalisés ou en cours de réalisation, ainsi qu’aux services complémentaires effectués. L’ensemble des droits d’auteur restent la propriété exclusive et entière du Prestataire, à l’exception des données fournies par le Client. Les fichiers et données sources crées et utilisées par le Prestataire ne sauraient dès lors être revendiquées par le Client sans une contribution financière. Les maquettes, et, plus largement, toutes les œuvres originales, restent la propriété du Prestataire, de même que les projets refusés. Ces documents doivent lui être rendus non endommagés et à sa demande. L’acompte déjà versé restera acquis par le Prestataire, constituant un dédommagement pour le travail entrepris.</p>
                    <p><b>10 &#8211; Cahier des charges et modifications</b></p>
                    <p>Pour permettre à Simon Pelletier de réaliser sa mission, le Client s’engage à établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des parties, après avoir été approuvé par Simon Pelletier. Au besoin Simon Pelletier pourra intervenir dans l’élaboration du cahier des charges. Toute demande de modification impliquant un remaniement substantiel du cahier des charges initial (notamment si le Client a omis dans celui-ci des informations ou que ces informations ne sont pas suffisamment précises), sera considérée comme une prestation supplémentaire et fera l’objet d’un nouveau devis. Le travail déjà effectué restera dû par le Client à Simon Pelletier. Le Client s’engage à fournir à Simon Pelletier tous les éléments dont il a besoin pour réaliser la prestation (texte,images, vidéos, sons &#8230;) au format demandé et ce dans des délais les plus brefs afin que Simon Pelletier dispose de suffisamment de temps pour réaliser la prestation dans de bonnes conditions et qu’il puisse respecter les délais du planning fixé entre les deux parties.</p>
                    <p><b>11 &#8211; Incapacité de travail</b></p>
                    <p>En cas d’incapacité de travail, par suite de maladie ou d’accident, le Prestataire se réserve le droit de modifier le calendrier en cours sans qu’il ne puisse être exigé par le Client le versement d’indemnités. Il est admis que le Prestataire se doit d’avertir le Client dès le premier jour ouvrable de son incapacité.</p>
                    <p><b>12 &#8211; La force majeure</b></p>
                    <p>Les parties ne peuvent être considérées comme responsables ou ayant faillies à leurs obligations contractuelles, lorsque le défaut d’exécution des obligations respectives a pour origine la force majeure ; le contrat entre les parties est suspendu jusqu’à l’extinction des causes ayant engendrées la force majeure. La force majeure prend en compte des faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles et indépendants de la volonté des parties, malgré tous les efforts raisonnablement possibles pour les empêcher. Sont aussi considérés comme cas de force majeure, le blocage des moyens de transports ou d’approvisionnements, tremblements de terre, incendies, tempêtes, inondations, foudre, l’arrêt des réseaux de télécommunication, et notamment tous les réseaux accessibles par Internet, ou difficultés propres aux réseaux de télécommunication extérieurs aux parties. La partie touchée par la force majeure en avisera l’autre dans les cinq (5) jours ouvrables suivant la date à laquelle elle en aura eu connaissance. Les deux parties conviendront alors des conditions dans lesquelles l’exécution du contrat sera poursuivie.</p>
                    <p><b>13 &#8211; Règlement des litiges</b></p>
                    <p>Le présent contrat est soumis au droit français. Tout différent lié à l’interprétation, l’exécution ou la validité du présent contrat sera soumis à la compétence exclusive du tribunal de commerce. En cas de litiges, les parties s’engagent à tout faire pour régler leurs différents à l’amiable. Au cas où une résolution amiable ne pourrait aboutir, la juridiction compétente est celle du lieu de domiciliation du Prestataire.</p>
                    <p><b>14 &#8211; Propriétés des travaux réalisés</b></p>
                    <p>La totalité de la production et des droits s’y rapportant, objet de la commande, demeure la propriété entière et exclusive du Prestataire tant que les factures émises ne sont pas payées en totalité par le Client, à concurrence du montant global de la commande et des avenants éventuels conclus en cours de prestation. De façon corollaire, le Client deviendra propriétaire de fait de la production et des droits cédés à compter du règlement final et soldant de toutes les factures émises par le Prestataire dans le cadre de la commande. Sauf mention contraire figurant sur le devis, les fichiers de production et les sources restent la propriété du Prestataire. Seul le produit fini sera adressé au Client. À défaut d’une telle mention et si le Client désire avoir les sources des documents, un avenant à ce présent document devra être demandé.</p>
                    <p><b>15 &#8211; Principes de cession</b></p>
                    <p>La reproduction et la réédition des créations du Prestataire sont soumises à la perception de droits d’auteur selon la loi du 11 mars 1957 (voir texte en annexe). La cession de ces droits ne concerne que l’utilisation spécifiquement prévue. Toute utilisation ultérieure ou différente nécessite une nouvelle convention. Les modifications ou interprétations d’une création graphique ne peuvent être faites, en aucun cas, sans le consentement du Prestataire. La signature ne peut être supprimée sans l’accord du Prestataire. Une idée proposée par le Client ne constitue pas, en soi, une création.</p>
                    <p><b>16 &#8211; Droits de reproduction et de diffusion</b></p>
                    <p>Les droits de reproduction et de diffusion sont calculés en fonction de la diffusion de la création. Ils peuvent être cédés forfaitairement ou partiellement. Chaque adaptation différente de l’œuvre originale faisant l’objet d’une nouvelle cession de droits d’auteur. Pour chaque nouvelle édition, le montant des droits doit être réactualisé. Les droits sont cédés dans le périmètre temporel et géographique du présent contrat et ne sauraient en excéder cette limite. L’ensemble des droits patrimoniaux relatifs à la création du Prestataire au titre du projet seront entièrement et exclusivement cédés au commanditaire lors du paiement effectif de l’intégralité des honoraires dus. Cela étant le commanditaire pourra exploiter librement la prestation fournie dans le cadre de son activité, et ce pour la diffusion sur les supports spécifiquement adressés lors de la commande.</p>
                    <p><b>17 &#8211; Copyright et mentions commerciales</b></p>
                    <p>Sauf mention contraire explicite du Client, le Prestataire se réserve la possibilité d’inclure dans la réalisation une mention commerciale indiquant clairement sa contribution, telle la formule « Réalisation graphique : Simon Pelletier ». Le Client s’engage à ne pas s’y opposer, et à ne jamais supprimer ces informations.</p>
                    <p><b>18 &#8211; Droit de publicité</b></p>
                    <p>Sauf mention contraire explicite du Client notifiée par courrier avec accusé de réception, Simon Pelletier se réserve le droit de mentionner ses réalisations effectuées pour le Client sur ses documents de communication externe et de publicité (site internet, portfolio, plaquette, etc.) et lors de ses démarchages de prospection commerciale. Ce droit s’étend plus particulièrement aux éléments constitutifs de la réalisation, comprenant sans restriction la présentation publique des contenus suivants : les contenus textuels, les contenus iconographiques.</p>
                    <p><b>Annexe : extraits de la loi n° 57-298 du 11 mars 1957 sur la propriété artistique (j.O. Du 14 mars 1957) des droits des auteurs.</b></p>
                    <p>Article 1 : L’auteur d’une œuvre de l’esprit jouit sur cette œuvre, du seul fait de sa création d’un droit de propriété incorporelle exclusif et opposable à tous. Ce droit comporte des attributs d’ordre intellectuel et moral, ainsi que des attributs d’ordre patrimonial, qui sont déterminés par la présente loi. L’existence ou la conclusion d’un contrat de louage d’ouvrage ou de service par l’auteur d’une œuvre de l’esprit n’emporte aucune dérogation à la jouissance du droit reconnu par l’alinéa premier.</p>
                    <p>Article 2 : Les dispositions de la présente loi protègent les droits des auteurs sur toutes les œuvres de l’esprit, quels qu’en soient le genre, la forme d’expression, le mérite ou la destination.</p>
                    <p>Article 3 : Sont considérés notamment comme œuvres de l’esprit au sens de la présente loi: les livres, brochures, et autres écrits littéraires, artistiques et scientifiques; les œuvres de dessin, de peintures, d’architecture, de sculpture, de gravure, de lithographie, les œuvres photographiques de caractère artistique ou documentale ou celles de même caractère obtenues par un procédé analogue à la photographie; les œuvres des arts appliqués, les illustrations, les cartes géographiques; les plans, croquis et ouvrages plastiques relatifs à la géographie, à la topographie, à l’architecture et aux sciences.</p>
                    <p>Article 6 : L’auteur jouit du droit au respect de son nom, de sa qualité et de son œuvre. Ce droit est attaché à sa personne. Il est perpétuel, inaliénable et imprescriptible. Il est transmissible à cause de mort aux héritiers de l’auteur. L’exercice peut en être conféré à un tiers en vertu des dispositions testamentaires.</p>
                    <p>Article 7 : L’œuvre est réputée créée, indépendamment de toute divulgation publique, du seul fait de la réalisation, même inachevée, de la conception de l’auteur.</p>
                    <p>Article 8 : La qualité d’auteur appartient, sauf preuve du contraire, à celui ou à ceux sous le nom de qui l’œuvre est divulguée.</p>
                    <p>Article 9 : Est dite œuvre de collaboration, l’œuvre à la création de laquelle ont concouru plusieurs personnes physiques. Est dite composite, l’œuvre nouvelle à laquelle est incorporée une œuvre préexistante sans la collaboration de l’auteur de cette dernière. Est dite collective, l’œuvre créée sur l’initiative d’une personne physique ou morale qui l’édite, la publie et la divulgue sous sa direction et sous son nom et dans laquelle la contribution personnelle des divers auteurs participants à son élaboration se fond dans l’ensemble en vue duquel elle est conçue, sans qu’il soit possible d’attribuer à chacun d’eux un droit distinct sur l’ensemble réalisé.</p>
                    <p>Article 21 : L’auteur jouit, sa vie durant, du droit exclusif d’exploiter son œuvre, sous quelque forme que ce soit et d’en tirer un profit pécuniaire. Au décès de l’auteur, ce droit persiste au bénéfice de ses ayants droits pendant l’année civile en cours et les cinquante années qui suivent. Pour les œuvres de collaboration, l’année civile prise en considération est celle de la mort du dernier vivant des collaborateurs. De l’exploitation des droits patrimoniaux de l’auteur.</p>
                    <p>Article 26 : Le droit d’exploitation appartenant à l’auteur comprend: le droit de représentation, le droit de reproduction.</p>
                    <p>Article 27 : La représentation consiste dans la communication directe de l’œuvre au public, notamment par voie de: présentation publique, diffusion des images par quelque procédé que ce soit.</p>
                    <p>Article 28 : La reproduction consiste en la fixation matérielle de l’œuvre par tous les procédés qui permettent de la communiquer au public d’une manière indirecte. Elle peut s’effectuer notamment par imprimerie, dessin, gravure, photographie, moulage et tout procédé des arts graphiques et plastiques, enregistrement mécanique, cinématographique, magnétique ou électronique.</p>
                    <p>Article 35 : La cession par l’auteur de ses droits sur son œuvre peut être totale ou partielle. Elle doit comporter au profit de l’auteur la participation proportionnelle aux recettes provenant de la vente ou de l’exploitation.</p>
                    <p>Article 38 : La clause de cession qui tend à conférer le droit d’exploiter l’œuvre sous une forme non prévisible ou non prévue à la date du contrat doit être expresse et stipuler une participation corrélative aux profits de l’exploitation.</p>
                </div>
            </div>
        )
    }
}

export default Cgv;