import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { updateFolio } from '../../store/actions/folioActions'
import { removeFolio } from '../../store/actions/folioActions'
import './folio.scss'

class FolioDetails extends Component {
  constructor(props) {
    super(props);
    const folio = props.folio;
    const id = props.id;
    if (folio) {
      this.state = {
        id: id,
        title: folio.title,
        description: folio.description,
        type: folio.type,
        link: folio.link,
        folder: folio.folder,
        miniature: folio.miniature,
        tools: folio.tools
      }
    }
  }

  state = {
    id: '',
    title: '',
    description: '',
    type: '',
    link: '',
    folder: '',
    miniature: '',
    tools: []
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  /**
   * Envoi en State les changement de la fonction onChange 
   */
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, () => {
      this.props.updateFolio(this.state);
    })
  }

  /**
   * A la suppression d'un folio
   */
  handleRemove = (e) => {
    e.preventDefault();
    this.props.removeFolio(this.state);
    // Redirection
    this.props.history.push('/');
  }

  /**
   * Retour au portfolio
   */
  backToPortfolio = (e) => {
    // Redirection
    this.props.history.push('/');
  }

  /**
   * Ajout d'un outil dans un folio
   */
  addTool = (e) => {
    e.preventDefault();
    this.setState({
      tools: [...this.state.tools, e.target.id]
    }, () => {
      this.props.updateFolio(this.state);
    })
  }

  /**
   * Supression d'un outil dans un folio
   */
  removeTool = (e) => {
    e.preventDefault();
    console.log(this.state)
    var tempTools = this.state.tools;
    var index = tempTools.indexOf(e.target.id);
    if (index > -1) {
      tempTools.splice(index, 1);
    }
    this.setState({
      tools: tempTools
    }, () => {
      this.props.updateFolio(this.state);
    })
  }

  /**
   * Retourne la vue Admin
   */
  adminView() {
    const self = this;
    const skills = this.props.skills;
    var skillList = [];
    if (skills !== undefined) {
      skillList = Object.keys(skills).map(function (key) {
        return skills[key];
      });
    }

    return (
      <span>
        <div className="btn deleteFolioBtn center-align col s12" onClick={this.handleRemove}>DELETE</div>
        <div className="input-field">
          <input type="text" id='title' value={this.state.title} onChange={this.handleChange} />
          <span className="helper-text grey-text">Title</span>
        </div>
        <div className="input-field">
          <input type="text" id='description' value={this.state.description} onChange={this.handleChange} />
          <span className="helper-text grey-text">Description</span>
        </div>
        <div className="input-field">
          <input type="text" id='type' value={this.state.type} onChange={this.handleChange} />
          <span className="helper-text grey-text">Type</span>
        </div>
        <div className="input-field">
          <input type="text" id='link' value={this.state.link} onChange={this.handleChange} />
          <span className="helper-text grey-text">Link</span>
        </div>

        {skillList.filter(skill => {
          return skill.category !== 'hobbies'
        }).map(function (skill, i) {
          return (
            <span key={i}>
              {
                self.state.tools.includes(skill.id)
                  ? <img src={skill.url} alt="tool" className="toolListImg toolActive" onClick={self.removeTool} id={skill.id} />
                  : <img src={skill.url} alt="tool" className="toolListImg toolDisable" onClick={self.addTool} id={skill.id} />
              }

            </span>
          )
        })
        }
      </span>
    )
  }

  /**
   * Retourne l'url de l'image d'un outil
   */
  findToolUrl = (id) => {
    const skills = this.props.skills;
    var skillList = [];
    var url = '';
    if (skills !== undefined) {
      skillList = Object.keys(skills).map(function (key) {
        return skills[key];
      });
      url = skillList.filter(function (skill, i) {
        return skill.id === id
      }).map(function (skill, i) {
        return skill.url
      })
    }
    return url[0]
  }

  /**
   * Retourne le nom d'un outil
   */
  findToolName = (id) => {
    const skills = this.props.skills;
    var skillList = [];
    var url = '';
    if (skills !== undefined) {
      skillList = Object.keys(skills).map(function (key) {
        return skills[key];
      });
      url = skillList.filter(function (skill, i) {
        return skill.id === id
      }).map(function (skill, i) {
        return skill.title
      })
    }
    return url[0]
  }

  /**
   * Retourne la vue Visiteur
   */
  visitorView() {
    const self = this;
    const folio = this.props.folio;
    const linkYt = 'https://www.youtube.com/embed/' + folio.link;

    return (
      <span className="folioDetails">
        <p className="folioDetailsTitle center domiC"><span className="crochetsInverse">{'< '}</span>{folio.title}<span className="crochetsInverse">{' >'}</span></p>
        <div className="center">

          {
            folio.type === 'app' ? <a className="folioDetailLink" href={folio.link} target="_blank" rel="noopener noreferrer"><span className="crochets domiC">[ </span> VISITER <span className="crochets domiC"> ]</span></a>
              : folio.type === 'video' ? <iframe className="videoFolio" title={folio.link} src={linkYt} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                : folio.type === 'print' ? null
                  : null
          }
        </div>
        <p className="folioDetailsDesc center">{folio.description}</p>

        <div className="center-align folioTools">
          {
            folio.tools
              ? folio.tools.map(function (id, i) {
                return (
                  <img src={self.findToolUrl(id)} alt="" title={self.findToolName(id)} className="folioToolsImg" key={i} />
                )
              })
              : null
          }
        </div>
      </span>
    )
  }

  /**
   * Quand les props sont mises à jour
   */
  componentWillReceiveProps(nextProps) {
    const folio = nextProps.folio;
    const id = nextProps.id;
    this.setState({
      id: id,
      title: folio.title,
      description: folio.description,
      type: folio.type,
      link: folio.link,
      folder: folio.folder,
      miniature: folio.miniature,
      tools: folio.tools
    })
  }

  render() {
    const folio = this.props.folio;
    const auth = this.props.auth;

    if (folio) {
      return (
        <div className="page folio-details section container">
          <div className="folio-summary">
            <div className="">

              {
                auth.uid
                  ? this.adminView()
                  : this.visitorView()
              }
              {
                folio.folder.map(function (url, i) {
                  return (
                    <div className="container folioFolderContainer" key={i}>
                      <img src={url} alt="" className="folioFolderImg" />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="container center">
          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div><div className="gap-patch">
                <div className="circle"></div>
              </div><div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const folios = state.firestore.data.folios;
  const skills = state.firestore.data.skills;
  const folio = folios ? folios[id] : null
  return {
    folio: folio,
    auth: state.firebase.auth,
    id: id,
    skills: skills
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateFolio: (folio) => dispatch(updateFolio(folio)),
    removeFolio: (folio) => dispatch(removeFolio(folio))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'folios' },
    { collection: 'skills' }
  ])
)(FolioDetails)
